import agent from '../agent'

export const PARTNER_INFO = 'partner/PARTNER_INFO'
export const PARTNER_REQUESTED = 'partner/PARTNER_REQUESTED'
export const PARTNER_CONFIRMATION_REQUESTED = 'partner/PARTNER_CONFIRMATION_REQUESTED'
export const PARTNER_REGISTER = 'partner/PARTNER_REGISTER'
export const PARTNER_ERROR = 'partner/PARTNER_ERROR'
export const PARTNER_CONFIRMATION_ERROR = 'partner/PARTNER_CONFIRMATION_ERROR'
export const PARTNER_CONFIRMATION = 'partner/PARTNER_CONFIRMATION'
export const PARTNER_WITHDRAW_CASH = 'partner/PARTNER_WITHDRAW_CASH'
export const PARTNER_WITHDRAW_REQEUSTED = 'partner/PARTNER_WITHDRAW_REQEUSTED'
export const PARTNER_WITHDRAW_ERROR = 'partner/PARTNER_WITHDRAW_ERROR'

const initialState = {
  loading: false,
  available_points: null,
  users_list: [],
  my_url: null,
  legend: [],
  message: null,
  error_message: null,
  activate_message: null,
  token: null,
  status: null,
  leaderboard: {
    monthly: [],
    yearly: [],
    monthly_description: '',
    yearly_description: ''
  },
  prizes: {
    monthly: [],
    yearly: []
  },
  monthly_position: 0
}

export default (state = initialState, action) => {
  switch (action.type) {

    case PARTNER_WITHDRAW_REQEUSTED:
    case PARTNER_CONFIRMATION_REQUESTED:
    case PARTNER_REQUESTED:
      return {
        ...state,
        loading: true
      }

    case PARTNER_REGISTER:
      return {
        ...state,
        loading: false,
        status: 1
      }

    case PARTNER_INFO:
      const { available_points, users_list, my_url, active, legend, leaderboard, prizes, monthly_position, monthly_total_position, yearly_total_points, yearly_position } = action.response.data.result
      return {
        ...state,
        loading: false,
        available_points,
        users_list,
        my_url,
        status: active,
        message: null,
        error_message: null,
        legend,
        leaderboard,
        prizes,
        monthly_position,
        monthly_total_position,
        yearly_total_points,
        yearly_position
      }

    case PARTNER_WITHDRAW_CASH:
      const { result: { available_points: points, message } } = action.response.data
      return {
        ...state,
        loading: false,
        available_points: points,
        message: message
      }

    case PARTNER_WITHDRAW_ERROR:
    case PARTNER_CONFIRMATION_ERROR:
    case PARTNER_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    case PARTNER_CONFIRMATION:
      return {
        ...state,
        loading: false,
        activate_message: 'Your partner account was activated',
      }
    default:
      return state
  }
}

export const get = (callback) => dispatch => {
  dispatch({ type: PARTNER_REQUESTED })

  agent.Routes.partner.get().then(dispatch, PARTNER_INFO, PARTNER_ERROR, callback)
}

export const createPartner = (form, callback) => dispatch => {
  dispatch({ type: PARTNER_REQUESTED })

  agent.Routes.partner.create(form).then(dispatch, PARTNER_REGISTER, PARTNER_ERROR, callback)
}

export const confirmPartner = (t, user_id, callback) => dispatch => {
  dispatch({ type: PARTNER_CONFIRMATION_REQUESTED })

  return agent.Routes.partner.activate(t, user_id).then(dispatch, PARTNER_CONFIRMATION, PARTNER_CONFIRMATION_ERROR, callback)
}

export const withdrawCash = (amount, callback) => dispatch => {
  dispatch({ type: PARTNER_WITHDRAW_REQEUSTED })

  return agent.Routes.partner.withdraw(amount).then(dispatch,  PARTNER_WITHDRAW_CASH, PARTNER_WITHDRAW_ERROR, callback)
}