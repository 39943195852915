import { combineReducers } from 'redux'
import auth from './auth'
import predictions from './predictions'
import live from './live'
import fixtures from './fixtures'
import comparison from './comparison'
import subscriptions from './subscriptions'
import promotion from './promotion'
import account from './account'
import partner from './partner'

export default combineReducers({
  auth, predictions, live, fixtures, comparison, subscriptions, promotion, account, partner
})
