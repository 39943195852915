// export const BASE_URL = `https://${window.location.hostname}`
export const API_BASE_URL = `https://${window.location.hostname}:8000`
// export const ENV = process.env.NODE_ENV
// export const CDN_SERVER = `https://${window.location.hostname}:8022`
export const CDN_SERVER = `https://${window.location.hostname}:8022`
export const LOGIN_PAGE = '/auth/login'


class File {
  baseUrl
  source

  constructor(baseUrl, source) {
    this.source = source
    this.baseUrl = baseUrl
  }

  get(data) {
    if (!data) {
      data = ''
    }
    return data.indexOf('http') > -1 ? data : `${this.baseUrl}/${this.source}/${data}`
  }
}

export const getCommonFile = data => (new File(CDN_SERVER, 'common')).get(data ? data.replace('images/', '') : '')
export const getTeamFile = data => (new File(CDN_SERVER, 'teams')).get(data ? data.replace('images/', '') : '')
export const getTipsFile = data => (new File(CDN_SERVER, 'live_tips')).get(data ? data.replace('images/', '') : '')
