// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getMessaging, onMessage, getToken} from "firebase/messaging";
import {NotificationManager} from "react-notifications";

export default class FireNotifications {

    app;
    analytics;
    messaging;
    jwt_token;

    firebaseConfig = {
        apiKey: "AIzaSyAhSmuT1cyDIShbQ07cf3F-a9DO5FhfVwA",
        authDomain: "live2020s-27cb8.firebaseapp.com",
        databaseURL: "https://live2020s-27cb8.firebaseio.com",
        projectId: "live2020s-27cb8",
        storageBucket: "live2020s-27cb8.appspot.com",
        messagingSenderId: "824303430763",
        appId: "1:824303430763:web:c7ad3260323f694a",
        measurementId: "G-02S6MS41SB",
        vapidKey: 'BL_Hxz1GptjoIoJzGmnXvnWIJUKMWUdQrLNAtIeWNKEAYCWjm_Lm37U2D6TNKC6CtMfy6tU1K1J6cJP3MCpinPw'
    }

    constructor(jwt_token) {
        this.app = initializeApp(this.firebaseConfig)
        this.analytics = getAnalytics(this.app)
        this.messaging = getMessaging(this.app);
        this.jwt_token = `Bearer ${jwt_token}`
    }

    onMessage(payload) {
        console.info('on-message not implemented', payload)
        return payload
    }
    
    async register(topic = null) {
        await Notification.requestPermission()
        
        onMessage(this.messaging, this.onMessage);

        try {
            let token = await getToken(this.messaging, {vapidKey: this.firebaseConfig.vapidKey})
            if (token) {
                await this.subscribe(token, topic)
            } else {
                console.error('No registration token available. Request permission to generate one.');
            }
        } catch (e) {
            console.error('An error occurred while retrieving token. ', e);
        }
    }

    async subscribe(token) {
        return fetch(`https://${window.location.hostname}:8000/api/communication/subscribe`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': this.jwt_token
            },
            body: JSON.stringify({token, web: true})
        }).then(async res => res.json())
    }

    static async load() {
        try {
            const notifications = new FireNotifications(localStorage.getItem('jwt'));
            notifications.onMessage = (payload) => {
                if (window.$("#live-chat").hasClass("closed")) {
                    NotificationManager.success(payload.notification.body, payload.notification.title);
                }
            }
            await notifications.register()
        } catch(e) {
            console.error('fire notifications register', e)
        }
    }
    
}
