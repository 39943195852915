import agent from '../agent'

export const EXTEND_SUBSCRIPTION_REQUESTED = 'live/EXTEND_SUBSCRIPTION_REQUESTED'
export const SUBSCRIPTION_REQUESTED = 'live/SUBSCRIPTION_REQUESTED'
export const SUBSCRIPTION_ERROR = 'live/SUBSCRIPTION_ERROR'
export const SUBSCRIPTION = 'live/SUBSCRIPTION'


const initialState = {
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SUBSCRIPTION:
      return {
        ...state,
        loading: false,
      }

    case SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const paypal = (type, success, failed) => dispatch => {
  dispatch({ type: SUBSCRIPTION_REQUESTED })

  return agent.Routes.subscriptions.paypal({type}).then(dispatch, success, failed)
}
export const paypalWeb = (data, success, failed) => dispatch => {
  dispatch({ type: SUBSCRIPTION_REQUESTED })

  return agent.Routes.subscriptions.paypalWeb(data).then(dispatch, success, failed)
}

export const extend = (type, success, failed) => dispatch => {
  dispatch({ type: EXTEND_SUBSCRIPTION_REQUESTED })

  return agent.Routes.partner.extend({type}).then(dispatch, success, failed)
}
