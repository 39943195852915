import agent from '../agent'

export const PROMOTION_REQUESTED = 'live/PROMOTION_REQUESTED'
export const PROMOTION_ERROR = 'live/PROMOTION_ERROR'
export const PROMOTION = 'live/PROMOTION'

export const PROMOTION_DATA_SEND_REQUESTED = 'live/PROMOTION_DATA_SEND_REQUESTED'
export const PROMOTION_DATA_SEND_ERROR = 'live/PROMOTION_DATA_SEND_ERROR'
export const PROMOTION_DATA_SEND = 'live/PROMOTION_DATA_SEND'


export const PROMOTION_ACTIVATE_REQUESTED = 'live/PROMOTION_ACTIVATE_REQUESTED'
export const PROMOTION_ACTIVATE_ERROR = 'live/PROMOTION_ACTIVATE_ERROR'
export const PROMOTION_ACTIVATE = 'live/PROMOTION_ACTIVATE'



const initialState = {
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case PROMOTION:
      return {
        ...state,
        loading: false,
        details: action.response.data.result 
      }
      
    case PROMOTION_DATA_SEND:
      return {
        ...state,
        loading: false
      }

    case PROMOTION_DATA_SEND_ERROR:
    case PROMOTION_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const getPromotion = () => dispatch => {
  dispatch({ type: PROMOTION_REQUESTED })

  return agent.Routes.promotion.get().then(dispatch, PROMOTION, PROMOTION_ERROR)
}

export const sendData = (data, callback) => dispatch => {
  dispatch({ type: PROMOTION_DATA_SEND_REQUESTED })

  return agent.Routes.promotion.send(data).then(dispatch, PROMOTION_DATA_SEND, PROMOTION_DATA_SEND_ERROR, callback)
}


export const activatePromo = (data, callback) => dispatch => {
  dispatch({ type: PROMOTION_ACTIVATE_REQUESTED })

  return agent.Routes.promotion.activate(data).then(dispatch, PROMOTION_ACTIVATE, PROMOTION_ACTIVATE_ERROR, callback)
}


