import agent from '../agent'

import moment from 'moment'

export const FILTERS_REQUESTED = 'fixtures/FILTERS_REQUESTED'
export const FILTERS_ERROR = 'fixtures/FILTERS_ERROR'
export const FILTERS = 'fixtures/FILTERS'

export const FIXTURES_REQUESTED = 'fixtures/FIXTURES_REQUESTED'
export const FIXTURES_ERROR = 'fixtures/FIXTURES_ERROR'
export const FIXTURES = 'fixtures/FIXTURES'

export const SELECT_FILTER_ITEM = 'fixtures/SELECT_FILTER_ITEM'
export const SET_FILTER_ITEM = 'fixtures/SET_FILTER_ITEM'
export const TOGGLE_FAVORITE_COMPETITION = 'fixtures/TOGGLE_FAVORITE_COMPETITION'
export const ADD_GAME_TO_FAVORITES = 'fixtures/ADD_GAME_TO_FAVORITES'
export const REMOVE_GAME_TO_FAVORITES = 'fixtures/REMOVE_GAME_TO_FAVORITES'
export const TOGGLE_PIPE = 'fixtures/TOGGLE_PIPE'

const initialState = {
  loading: false,
  sport: 'football',
  pipe: {
    hide_unmet_threshold: false,
    hide_competition_name: false
  },
  filters: {},
  selected: {},
  fixtures: [],
  favorite_competitions: [],
  favorite_games: [], // bet slip
  memory: {
    //sport: {}
  }
}


export default (state = initialState, action) => {
  switch (action.type) {

    case SELECT_FILTER_ITEM:
      state.selected[action.filter] = action.value

      return state
    case TOGGLE_PIPE:
      state.pipe[action.key] = !state.pipe[action.key]
      return state
    case ADD_GAME_TO_FAVORITES:
      state.favorite_games.push(action.game)

      return state
    case REMOVE_GAME_TO_FAVORITES:
      state.favorite_games.splice(action.index, 1)

      return state
    case TOGGLE_FAVORITE_COMPETITION:
      if (state.favorite_competitions.indexOf(action.competition_id) > -1) {
        state.favorite_competitions = state.favorite_competitions.filter(id => id !== action.competition_id)
      } else {
        state.favorite_competitions.push(action.competition_id)
      }
      return state
    case SET_FILTER_ITEM:
      state.filters[action.filter] = action.value

      return state
    case FILTERS_REQUESTED:
      let cache_keys = ['filters', 'selected', 'fixtures', 'favorite_competitions']
      if (!state.memory.hasOwnProperty(state.sport)) {
        state.memory[state.sport] = {}
      }
      if (action.sport !== state.sport) {
        // save current to memory
        cache_keys.forEach(key => {
          state.memory[state.sport][key] = state[key]
        })
        if (state.memory[action.sport]) {
          // overwrite from memory
          cache_keys.forEach(key => {
            state[key] = state.memory[action.sport][key]
          })
        }
      }
      return {
        ...state,
        loading: true,
        sport: action.sport
      }
    case FILTERS:
      return {
        ...state,
        loading: false,
        filters: action.response.data.fixtures
      }
    case FIXTURES:
      return {
        ...state,
        loading: false,
        fixtures: Object.values(action.response.data.fixtures).reduce((carry, games) => {
          return carry.concat([{
            country: games[0].country,
            competition: games[0].competition,
            competition_id: games[0].competition_id,
            games: games.map(game => ({
              ...game,
              time: moment(game.date).format('HH:mm')
            }))
          }])
        }, [])
      }
    case FIXTURES_ERROR:
    case FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const togglePipe = (key) => dispatch => {
  dispatch({ type: TOGGLE_PIPE, key })
}
export const removeGameFromFavorites = (index) => dispatch => {
  dispatch({ type: REMOVE_GAME_TO_FAVORITES, index })
}
export const selectFilterItem = (filter, value) => dispatch => {
  dispatch({ type: SELECT_FILTER_ITEM, filter, value })
}

export const toggleFavoriteCompetition = (competition_id) => dispatch => {
  dispatch({ type: TOGGLE_FAVORITE_COMPETITION, competition_id })
}
export const addGameToFavorites = (game) => dispatch => {
  dispatch({ type: ADD_GAME_TO_FAVORITES, game })
}

export const setFilterItem = (filter, value) => dispatch => {
  dispatch({ type: SET_FILTER_ITEM, filter, value })
}

export const getFilters = (sport, time) => dispatch => {
  dispatch({ type: FILTERS_REQUESTED, sport })

  agent.Routes.fixtures.filters(sport, time).then(dispatch, FILTERS, FILTERS_ERROR)
}

export const getFixtures = (data, callback) => dispatch => {
  dispatch({ type: FIXTURES_REQUESTED, data })

  agent.Routes.fixtures.get(data).then(dispatch, FIXTURES, FIXTURES_ERROR, callback)
}

