import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import store, {history} from './store'
import {throttle} from 'lodash'
import * as query from 'query-string';

import moment from 'moment-timezone'
import $ from 'jquery'
import * as Sentry from '@sentry/browser';

import FireNotifications from "./containers/app/notifications";
import { createRoot } from 'react-dom/client'
try {
    FireNotifications.load()
} catch (e) {

}
document.onclick = function () {
    if (window.manual_fcm_loaded) {
        return
    }
    FireNotifications.load()
    window.manual_fcm_loaded = true
}

Sentry.init({dsn: "https://260c07209a68429b9d430a1a7602e9f4@sentry.io/3341265"});

if (!window.$) {
    window.$ = $;
}
window.query = (key) => key ? query.parse(window.location.search)[key] : query.parse(window.location.search)

if (window.query('hide_menu')) {
    window.localStorage.setItem('mobile_hide_menu', '1');
}

window.moment = moment
window.local_timezone = window.moment.tz.guess()
window.convertToLocal = (date, format) => {
    if (!format) {
        format = "YYYY-MM-DD HH:mm"
    }
    return window.moment.utc(date).tz(window.local_timezone).format(format)
}


store.subscribe(throttle(() => {
    try {
        const serializedState = JSON.stringify(store.getState())
        localStorage.setItem('state', serializedState)
    } catch (e) {
        console.error(e)
    }
}, 100));

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const ref = urlParams.get('ref')

if (ref) {
    localStorage.setItem('ref', ref)
}


import('./containers/app').then(({default: App}) => {
    const container = document.querySelector('#root');
    const root = createRoot(container);

    root.render(
      <Provider store={store}>
          <ConnectedRouter history={history}>
              <App history={history}/>
          </ConnectedRouter>
      </Provider>
    );
});
