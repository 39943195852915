import agent from '../agent'

export const TIPS_REQUESTED = 'live/TIPS_REQUESTED'
export const TIPS_ERROR = 'live/TIPS_ERROR'
export const TIPS = 'live/TIPS'


const initialState = {
  loading: false,
  tips: []
}

export default (state = initialState, action) => {
  switch (action.type) {

    case TIPS:
      return {
        ...state,
        loading: false,
        tips: action.response.data.tips
      }

    case TIPS_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const get = () => dispatch => {
  dispatch({ type: TIPS_REQUESTED })

  agent.Routes.tips().then(dispatch, TIPS, TIPS_ERROR)
}

export const filter = (date) => dispatch => {
  dispatch({ type: TIPS_REQUESTED })

  agent.Routes.tips(date).then(dispatch, TIPS, TIPS_ERROR)
}
