import agent from '../agent'

export const ACCOUNT_REQUESTED = 'live/ACCOUNT_REQUESTED'
export const ACCOUNT_ERROR = 'live/ACCOUNT_ERROR'
export const ACCOUNT = 'live/ACCOUNT'

export const NEWSLETTER_REQUESTED = 'live/NEWSLETTER_REQUESTED'
export const NEWSLETTER_ERROR = 'live/NEWSLETTER_ERROR'
export const NEWSLETTER = 'live/NEWSLETTER'

const initialState = {
  loading: false,
  newsletter: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case ACCOUNT:
      return {
        ...state,
        loading: false,
        ...action.response.data
      }
    case NEWSLETTER:
      return {
        ...state,
        loading: false,
        newsletter: action.response.data.result
      }

    case ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const getAccountDetails = () => dispatch => {
  dispatch({ type: ACCOUNT_REQUESTED })

  agent.Routes.account.getDetails().then(dispatch, ACCOUNT, ACCOUNT_ERROR)
}

export const toggleNewsletter = (status) => dispatch => {
  dispatch({ type: NEWSLETTER_REQUESTED, status })

  agent.Routes.account.toggleNewsletter(status).then(dispatch, NEWSLETTER, NEWSLETTER_ERROR)
}


export const toggleSetting = (key, active, callback) => dispatch => {
  agent.Routes.account.toggleSetting(key, active).then(dispatch, NEWSLETTER, NEWSLETTER_ERROR, callback)
}


