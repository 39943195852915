import agent from '../agent'
import { push } from 'connected-react-router'
import LogRocket from 'logrocket'

import { LOGIN_PAGE } from '../env'

export const LOGIN_REQUESTED = 'auth/LOGIN_REQUESTED'
export const LOGIN_ERROR = 'auth/LOGIN_ERROR'
export const LOGIN = 'auth/LOGIN'

export const REGISTER_REQUESTED = 'auth/REGISTER_REQUESTED'
export const REGISTER = 'auth/REGISTER'
export const REGISTER_ERROR = 'auth/REGISTER_ERROR'

export const LOGOUT = 'auth/LOGOUT'
export const LOGOUT_ERROR = 'auth/LOGOUT_ERROR'

export const CONFIG = 'auth/CONFIG'
export const CONTACT = 'auth/CONTACT'
export const CONTACT_ERROR = 'auth/CONTACT_ERROR'

export const USER = 'auth/USER'
export const USER_ERROR = 'auth/USER_ERROR'
export const USER_REQUESTED = 'auth/USER_REQUESTED'

export const ACTIVATE = 'auth/ACTIVATE'
export const ACTIVATE_ERROR = 'auth/ACTIVATE_ERROR'
export const ACTIVATE_REQUESTED = 'auth/ACTIVATE_REQUESTED'

export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const RESET_PASSWORD_ERROR = 'auth/RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_REQUESTED = 'auth/RESET_PASSWORD_REQUESTED'

export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_ERROR = 'auth/CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_REQUESTED = 'auth/CHANGE_PASSWORD_REQUESTED'

export const CLEAR_ERROR_MESSAGE = 'auth/CLEAR_ERROR_MESSAGE'


const initialState = {
  loading: false,
  token: null,
  user: null,
  error_message: null,
  message: null,
  activate_message: null,
  sports: [],
  subscriptions: []
}

const identifyUser = (name, email, days) => {
  LogRocket.identify('zeplao/live2020s', {
    name: name,
    email: email,

    // Add your own custom user variables here, ie:
    subscriptionType: days > 0 ? 'unsubscribed' : 'subscribed'
  })
}

export default (state = initialState, action) => {
  switch (action.type) {

    case CONTACT_ERROR:
      alert('Your message has not been sent. Please try again later.')
      break
    case CONTACT:
      alert('Your message has been sent.')
      break
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error_message: null
      }

    case CHANGE_PASSWORD_REQUESTED:
    case ACTIVATE_REQUESTED:
    case REGISTER_REQUESTED:
    case LOGIN_REQUESTED:
      return {
        ...state,
        loading: true
      }

    case LOGIN:

      agent.setToken(action.response.data.token)
      identifyUser(
        action.response.data.user.name,
        action.response.data.user.email,
        action.response.data.diff
      )

      return {
        ...state,
        loading: false,
        token: action.response.data.token,
        user: {
          ...action.response.data.user,
          remaining_days: action.response.data.diff
        }
      }

    case CHANGE_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
    case ACTIVATE_ERROR:
    case REGISTER_ERROR:
    case LOGOUT_ERROR:
    case LOGIN_ERROR:
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data,
        activate_message: null
      }

    case REGISTER:
      setTimeout(() => {
        window.location.href = '/auth/login'
      }, 500)

      return {
        ...state,
        loading: false
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: false
      }
    case CONFIG:
      return {
        ...state,
        loading: false,
        sports: action.response.data.config.sports,
        subscriptions: action.response.data.config.subscriptions
      }
    case LOGOUT:
      localStorage.removeItem('jwt')
      // delete cookie
      document.cookie = `auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=Lax`;
      window.location.href = '/'
      return {
        ...state,
        user: null
      }

    case USER:
      if (action.response.data.user) {
        action.response.data.user.remaining_days =
          action.response.data.user.diff
      }
      identifyUser(
        state.user.name,
        state.user.email,
        action.response.data.user.remaining_days
      )

      return {
        ...state,
        loading: false,
        user: {
          ...action.response.data.user
        }
      }
    case ACTIVATE:
      return {
        ...state,
        loading: false,
        activate_message: action.response.data.message,
        error_message: null
      }
    case RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        error_message: null
      }
    default:
      return state
  }
}

export const login = (email, password) => dispatch => {
  dispatch({ type: LOGIN_REQUESTED })

  agent.Auth.login({ email, password }).then(
    dispatch,
    response => {
      if (response.data.token) {
        document.cookie = `auth_token=${response.data.token}; path=/; Secure; SameSite=Lax`;
        console.log('setting cookie', response.data.token, document.cookie)
      }

      dispatch({ type: LOGIN, response })

      setTimeout(() => {
        if (window.location.href.indexOf('?redirect=') > -1) {
          window.location.href = window.query('redirect')
        } else {
          window.location.href = '/'
        }
      }, 400)
    },
    LOGIN_ERROR
  )
}

export const logout = () => dispatch => {
  agent.Auth.logout()
  dispatch({ type: LOGOUT })
  dispatch(push(LOGIN_PAGE))
}

export const register = data => dispatch => {
  dispatch({ type: REGISTER_REQUESTED })

  agent.Auth.register(data).then(
    dispatch,
    () => {
      dispatch({ type: REGISTER })
    },
    REGISTER_ERROR
  )
}

export const user = callback => dispatch => {
  dispatch({ type: USER_REQUESTED })

  if (callback) {
    agent.Auth.user().then(dispatch, USER, USER_ERROR, callback)
  } else {
    agent.Auth.user().then(dispatch, USER, USER_ERROR)
  }
}
export const activate = (t, callback) => dispatch => {
  dispatch({ type: ACTIVATE_REQUESTED })

  return agent.Auth.activate(t).then(
    dispatch,
    ACTIVATE,
    ACTIVATE_ERROR,
    callback
  )
}

export const unsubscribe = (t, callback) => dispatch => {
  dispatch({ type: ACTIVATE_REQUESTED })

  return agent.Auth.unsubscribe(t).then(
    dispatch,
    ACTIVATE,
    ACTIVATE_ERROR,
    callback
  )
}

export const getConfig = cb => dispatch => {
  if (cb) {
    return agent.Routes.config().then(dispatch, CONFIG, null, cb)
  }
  agent.Routes.config().then(dispatch, CONFIG)
}
export const goToRegister = cb => dispatch => {
  window.location.href = '/auth/register'
}

export const clear = () => dispatch => {
  dispatch({ type: CLEAR_ERROR_MESSAGE })
  return true
}

export const contact = data => dispatch => {
  agent.Routes.contact(data).then(dispatch, CONTACT, CONTACT_ERROR)
}

export const resetPassword = (t, callback) => dispatch => {
  dispatch({ type: RESET_PASSWORD_REQUESTED })

  return agent.Auth.reset(t).then(
    dispatch,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    callback
  )
}

export const changePassword = (t, callback) => dispatch => {
  dispatch({ type: CHANGE_PASSWORD_REQUESTED })

  return agent.Auth.changePassword(t).then(
    dispatch,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_ERROR,
    callback
  )
}
