import { applyMiddleware, compose, createStore } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import * as History from 'history'
import rootReducer from './modules'

export const history = History.createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

  middleware.push(createLogger())
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return initialState
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return initialState
  }
}

export default createStore(
  connectRouter(history)(rootReducer),
  loadState(),
  composedEnhancers
)
