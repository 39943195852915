import agent from '../agent'

export const TEAM_REQUESTED = 'comparison/TEAM_REQUESTED'
export const TEAM = 'comparison/TEAM'

export const COMPARISON_REQUESTED = 'comparison/COMPARISON_REQUESTED'
export const COMPARISON_ERROR = 'comparison/COMPARISON_ERROR'
export const COMPARISON = 'comparison/COMPARISON'

export const SWITCH_TOGGLE = 'comparison/SWITCH_TOGGLE'
export const SET_SPORT = 'comparison/SET_SPORT'


const initialState = {
  loading: false,
  team1: {},
  team2: {},
  games1: {},
  games2: {},
  radar_data: [],
  teams: {},
  selected_switch: [],
  sport: null,
  memory: {}
}

export default (state = initialState, action) => {
  switch (action.type) {

    case COMPARISON_REQUESTED:
      return {
        ...state,
        loading: true
      }

    case COMPARISON:
      let teams = action.response.data.comparison.teams
      let selected = action.response.data.comparison.radar_data.map(el => el.key)
      selected = selected.slice(0,5)
      return {
        ...state,
        loading: false,
        games1: action.response.data.comparison.game_a,
        games2: action.response.data.comparison.game_b,
        radar_data: action.response.data.comparison.radar_data,
        selected_switch: selected,
        teams: teams,
        team1: {
          value: teams[action.response.data.comparison.home].id,
          label: teams[action.response.data.comparison.home].label,
          image: teams[action.response.data.comparison.home].image
        },
        team2: {
          value: teams[action.response.data.comparison.away].id,
          label: teams[action.response.data.comparison.away].label,
          image: teams[action.response.data.comparison.home].image
        },
        sport: action.response.data.comparison.sport
      }

    case TEAM:
      state[action.key] = action.value
      return state

    case SET_SPORT:
      // let cache_keys = ['team1', 'team2', 'games1', 'games2', 'radar_data', 'teams']
      // if (!state.memory.hasOwnProperty(state.sport)) {
      //   state.memory[state.sport] = {}
      // }
      // if (action.sport !== state.sport) {
      //   // save current to memory
      //   cache_keys.forEach(key => {
      //     state.memory[state.sport][key] = state[key]
      //   })
      //   if (state.memory[action.sport]) {
      //     // overwrite from memory
      //     cache_keys.forEach(key => {
      //       state[key] = state.memory[action.sport][key]
      //     })
      //   } else {
      //     cache_keys.forEach(key => {
      //       state[key] = initialState[key]
      //     })
      //   }
      // }
      return {
        ...state,
        sport: action.sport,
        team1: {},
        team2: {},
        games1: {},
        games2: {},
        teams: {},
      }

    case SWITCH_TOGGLE:
      return {
        ...state,
        selected_switch: state.selected_switch.indexOf(action.value) > -1
          ? state.selected_switch.filter(el => el !== action.value)
          : state.selected_switch.concat([action.value])
      }

    case COMPARISON_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const get = (team1, team2, sport, callback) => dispatch => {
  dispatch({ type: COMPARISON_REQUESTED, team1, team2, sport })

  agent.Routes.comparison.get(team1, team2, sport).then(dispatch, COMPARISON, COMPARISON_ERROR, callback)
}

export const setTeam = (key, value) => dispatch => {
  dispatch({ type: TEAM, key, value })
}
export const setSport = (sport) => dispatch => {
  dispatch({ type: SET_SPORT, sport })
}
export const toggleSwitch = (value) => dispatch => {
  dispatch({ type: SWITCH_TOGGLE, value })
}

export const getTeams = (name, sport) => dispatch => {
  dispatch({ type: TEAM_REQUESTED, name, sport })

  return agent.Routes.comparison.filters(name, sport)
}
