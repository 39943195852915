import agentPromise from 'superagent-promise'
import _agent from 'superagent'
import { API_BASE_URL, LOGIN_PAGE } from './env'
import { push } from 'connected-react-router'

const agent = agentPromise(_agent, Promise)

const API_ROOT = API_BASE_URL + '/api/'

class Response {
  constructor(code, data) {
    this.code = code
    this.data = data
  }

  isSuccess = () => this.code === 200
  isForbidden = () => this.code === 403
}

class AgentRequest {
  constructor(promise) {
    this.promise = promise
  }

  then = (dispatch, SUCCESS, ERROR, callback) => this.promise
    .then(response => {
      if (response.isForbidden()) {
        return dispatch(push(LOGIN_PAGE))
      }

      if (response.isSuccess()) {
        if (typeof SUCCESS === 'function') {
          SUCCESS(response)
        } else {
          dispatch({ type: SUCCESS, response })
        }
      } else {
        if (typeof ERROR === 'function') {
          ERROR(response)
        } else {
          dispatch({ type: ERROR, response })
        }
      }
      if (callback) {
        callback(response)
      }
    })
    .catch(response => console.error('Did not catch the error in this situation', response))
}

const responseBody = res => new Response(res.body.status, res.body.data)
const errorResponseBody = res => new Response(res.response.body.error.code, res.response.body.error.message)

let token = ''
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Bearer ${token}`)
  } else {
    req.set('authorization', `Bearer ${localStorage.getItem('jwt')}`)
  }
}

const requests = {
  get: (url, body) => agent.get(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody).catch(errorResponseBody),
  post: (url, body) => agent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody).catch(errorResponseBody)
}

const Auth = {
  login: ({ email, password }) => new AgentRequest(requests.post('users/login', { email, password, partner_id: localStorage.getItem('ref') })),
  logout: () => new AgentRequest(requests.get('users/logout')),
  user: () => new AgentRequest(requests.get('users/logged-in-user', {partner_id: localStorage.getItem('ref')})),
  register: (data) => new AgentRequest(requests.post('users/register', {...data, partner_id: localStorage.getItem('ref') })),
  activate: (t) => new AgentRequest(requests.get('users/activate', {t})),
  reset: (t) => new AgentRequest(requests.get('users/request-reset-password', t)),
  changePassword: (t) => new AgentRequest(requests.post('users/reset-password', t)),
  unsubscribe: (token) => new AgentRequest(requests.get('users/unsubscribe', {token})),
}

const Routes = {
  contact: (data) => new AgentRequest(requests.post('communication/contact-us', data)),
  config: () => new AgentRequest(requests.get('devices/config')),
  predictions: () => new AgentRequest(requests.get('predictions')),
  tips: (date) => new AgentRequest(requests.get('live-tips', { date })),
  dailyTips: (date) => new AgentRequest(requests.get('daily-tips', { date })),
  fixtures: {
    filters: (sport, time) => new AgentRequest(requests.get('fixtures/filters', { sport, time})),
    get: (data) => new AgentRequest(requests.get('fixtures', data))
  },
  comparison: {
    filters: (name, sport) => new AgentRequest(requests.get('comparison/filters', { name, sport })),
    get: (home, away, sport) => new AgentRequest(requests.get('comparison', {
      sport,
      home: typeof home === 'string' ? home : home.value,
      away: typeof away === 'string' ? away : away.value
    }))
  },
  subscriptions: {
    paypal: ({type}) => new AgentRequest(requests.post('payments/paypal', {type})),
    paypalWeb: (data) => new AgentRequest(requests.post('payments/paypal-web', data)),
  },
  promotion: {
    get: () => new AgentRequest(requests.get('promotion')),
    send: ({email, _id, user_id}) => new AgentRequest(requests.post('promotion/data', {email, _id, user_id})),
    activate: (id) => new AgentRequest(requests.post('promotion/activate', {id})),
  },
  partner: {
    get: () => new AgentRequest(requests.get('partners/info')),
    create: (form) => new AgentRequest(requests.post('partners/subscribe', form)),
    activate: (token, user_id) => new AgentRequest(requests.post('partners/confirm', {token, user_id})),
    extend: ({type}) => new AgentRequest(requests.post('partners/withdraw/extend', {subscription_type: type})),
    withdraw: (points) => new AgentRequest(requests.post('partners/withdraw/cash', {points})),
  },
  account: {
    getDetails: () => new AgentRequest(requests.get('account/details')),
    toggleNewsletter: (status) => new AgentRequest(requests.post('account/edit/newsletter', {status})),
    toggleSetting: (key, active) => new AgentRequest(requests.post('account/edit/settings-toggle', {key, active})),
  },
  communication: {
    subscribe: (token) => new AgentRequest(requests.post('communication/subscribe', {token})),
  }
}

const validateToken = async () => {
  token = localStorage.getItem('jwt')
  await Auth.user().promise
    .then(response => {
      if (response.isForbidden()) {
        localStorage.removeItem('jwt')
        token = null
        if (window.location.href.indexOf(LOGIN_PAGE) === -1 && !window.query('promoactivation')) {
          window.location.href = LOGIN_PAGE + '?redirect=' + window.location.href
        }
      }
    })
    .catch(response => {
      console.error('validateToken-err', response)
    })
}

const isAuthenticated = () => {
  token = localStorage.getItem('jwt')

  return token !== null
}


export default {
  Auth, requests, Routes,
  setToken: _token => {
    localStorage.setItem('jwt', _token)
    token = _token
  },
  validateToken,
  isAuthenticated
}
