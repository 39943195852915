import agent from '../agent'

export const PREDICTIONS_REQUESTED = 'predictions/PREDICTIONS_REQUESTED'
export const PREDICTIONS_ERROR = 'predictions/PREDICTIONS_ERROR'
export const PREDICTIONS = 'predictions/PREDICTIONS'


const initialState = {
  loading: false,
  data: {},
  sports: [],
  stats: [],
  history: {}
}

export default (state = initialState, action) => {
  switch (action.type) {

    case PREDICTIONS:
      return {
        ...state,
        loading: false,
        data: action.response.data.predictions,
        sports: Object.keys(action.response.data.predictions),
        stats: Object.keys(action.response.data.stats.percentage).map(sport => ({
          sport, value: action.response.data.stats.percentage[sport]
        })),
        history: action.response.data.stats.history
      }

    case PREDICTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.response.data
      }

    default:
      return state
  }
}

export const get = () => dispatch => {
  dispatch({ type: PREDICTIONS_REQUESTED })

  agent.Routes.predictions().then(dispatch, PREDICTIONS, PREDICTIONS_ERROR)
}
